import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-slideshow', {
	attributes: [
		{
			attribute: 'auto',
			type: 'int',
		},
		{
			attribute: 'current',
			type: 'int',
		},
		{
			attribute: 'has-buttons',
			type: 'string',
		},
		{
			attribute: 'has-navigation',
			type: 'string',
		},
		{
			attribute: 'has-pagination',
			type: 'string',
		},
		{
			attribute: 'use-arrowkeys',
			type: 'boolean',
		},
		{
			attribute: 'use-click',
			type: 'boolean',
		},
	],
	controller: class extends BaseController {
		currentChangedCallback( from, to ) {
			this.emit( 'mr-slideshow:current', {
				current: to,
			} );

			console.log( `currentChangedCallback ${from} ${to}` );

			this.render();
		}

		autoChangedCallback() {
			this.start();
		}

		start() {
			this.stop();

			if ( this.auto && 0 < this.auto ) {
				this.looper = setInterval( () => {
					this.next();
				}, this.auto );
			}
		}

		stop() {
			if ( this.looper ) {
				clearInterval( this.looper );
				this.looper = null;
			}
		}

		next() {
			if ( this.current < this.elements.items.length - 1 ) {
				this.current = this.current + 1;
			} else {
				this.current = this.elements.items.length - 1;
			}
		}

		previous() {
			if ( 0 < this.current ) {
				this.current = this.current - 1;
			} else {
				this.current = 0;
			}
		}

		resolve() {
			if ( 1 === this.el.querySelectorAll( '.js-slideshow-item' ).length ) {
				// Keep hanging, don't activate if empty
				return new Promise( () => {} );
			}

			return super.resolve();
		}

		init() {
			this.elements = {};
			this.elements.track = this.el.querySelector( '.js-slideshow-track' );
			this.elements.items = Array.from( this.el.querySelectorAll( '.js-slideshow-item' ) );

			if ( this.hasButtons ) {
				this.elements.buttons = this.el.querySelector( '.js-slideshow-buttons' );
				this.createButtons();
			}

			if ( this.hasNavigation ) {
				this.createNav();
			}

			if ( this.hasPagination ) {
				this.createPagination();
			}

			if ( !this.current ) {
				this.current = 0;
			}

			this.emit( 'mr-slideshow:current', {
				current: this.current,
			} );

			this.start();
		}

		bind() {
			if ( this.useArrowkeys ) {
				this.on( 'keydown', ( e ) => {
					if ( 37 === e.keyCode ) {
						this.previous();
					} else if ( 39 === e.keyCode ) {
						this.next();
					}
				}, window, {
					passive: true,
				} );
			}

			if ( this.useClick ) {
				this.on( 'click .js-slideshow-item', ( e, slideshowItem ) => {
					const clickX = e.clientX;
					const boundingBox = slideshowItem.getBoundingClientRect();
					const clickWithinRightHalf = 0.5 < ( ( clickX - boundingBox.left ) / boundingBox.width );

					if ( clickWithinRightHalf ) {
						this.next();
					} else {
						this.previous();
					}
				} );
			}

			if ( this.navigation ) {
				this.navigation.forEach( ( item, index ) => {
					this.on( 'click', ( e ) => {
						if ( index !== this.current ) {
							e.preventDefault();
							this.current = index;
						}
					}, item );
				} );
			}

			if ( this.buttons ) {
				this.on( 'click', () => {
					this.next();
				}, this.buttons.next );

				this.on( 'click', () => {
					this.previous();
				}, this.buttons.previous );
			}

			const touch = {
				startX: 0,
				threshold: 100,
				allowedTime: 400,
				startTime: 0,
			};

			this.on( 'touchstart', ( e ) => {
				touch.startX = e.changedTouches[0].screenX;
				touch.startTime = new Date().getTime();
			} );

			this.on( 'touchend', ( e ) => {
				const distX = e.changedTouches[0].screenX - touch.startX;
				if ( new Date().getTime() - touch.startTime <= touch.allowedTime &&
					Math.abs( distX ) >= touch.threshold ) {
					if ( 0 > distX ) {
						this.next();
					} else {
						this.previous();
					}
				}
			} );
		}

		render() {
			if ( this.navigation ) {
				this.navigation.forEach( ( item, index ) => {
					if ( index === this.current ) {
						item.classList.add( 'is-active' );
					} else {
						item.classList.remove( 'is-active' );
					}
				} );
			}

			if ( this.buttons ) {
				this.buttons.previous.innerHTML = ( this.current + 1 );

				if ( 0 === this.current ) {
					this.buttons.previous.classList.add( 'is-disabled' );
				} else {
					this.buttons.previous.classList.remove( 'is-disabled' );
				}

				if ( this.current === ( this.elements.items.length - 1 ) ) {
					this.buttons.next.classList.add( 'is-disabled' );
				} else {
					this.buttons.next.classList.remove( 'is-disabled' );
				}
			}

			if ( this.pagination ) {
				this.pagination.textContent = `${this.current + 1} / ${this.elements.items.length}`;
			}

			this.scroll();
		}

		createNav() {
			const fragment = document.createDocumentFragment();
			const nav = document.createElement( 'div' );
			nav.classList.add( this.hasNavigation );

			this.navigation = this.elements.items.map( ( item, index ) => {
				const navItem = document.createElement( 'button' );
				navItem.textContent = index + 1;
				Object.assign( item.dataset, {
					galleryIndex: index,
				} );
				nav.appendChild( navItem );

				return navItem;
			} );

			fragment.appendChild( nav );
			this.el.appendChild( fragment );
		}

		createButtons() {
			const fragment = document.createDocumentFragment();

			const previous = document.createElement( 'button' );
			previous.classList.add( this.hasButtons );
			previous.classList.add( `${this.hasButtons}--previous` );
			previous.textContent = ( this.current + 1 );
			fragment.appendChild( previous );

			const next = document.createElement( 'button' );
			next.classList.add( this.hasButtons );
			next.classList.add( `${this.hasButtons}--next` );
			next.textContent = this.elements.items.length;
			fragment.appendChild( next );

			this.elements.buttons.appendChild( fragment );

			this.buttons = {
				previous,
				next,
			};
		}

		createPagination() {
			const fragment = document.createDocumentFragment();

			const pagination = document.createElement( 'p' );
			pagination.classList.add( this.hasPagination );
			pagination.textContent = `${this.current + 1} / ${this.elements.items.length}`;

			fragment.appendChild( pagination );
			this.el.appendChild( fragment );

			this.pagination = pagination;
		}

		scroll() {
			const position = this.elements.items[this.current].offsetLeft;
			const transform = `translateX(-${Math.round( position )}px)`;

			Object.assign( this.elements.track.style, {
				transform: transform,
				webkitTransform: transform,
			} );
		}

		destroy() {
			this.stop();
			super.destroy();
		}
	},
} );
