import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-scroll-header', {
	controller: class extends BaseController {
		resolve() {
			return Promise.resolve( true );
		}

		bind() {
			let isBlocked = false;
			let debounce;

			const handler = ( () => {
				const threshold = 160;
				let isScrolled = false;

				return () => {
					if ( !isScrolled && window.scrollY > threshold ) {
						isScrolled = true;

						window.requestAnimationFrame( () => {
							Object.assign( document.body.style, {
								'padding-top': `${this.el.offsetHeight}px`,
							} );

							document.body.classList.add( 'is-scrolled' );

							this.showNav = setTimeout( () => {
								document.body.classList.add( 'is-showing-nav' );
							}, 16 );
						} );
					} else if ( isScrolled && window.scrollY <= threshold ) {
						isScrolled = false;

						window.requestAnimationFrame( () => {
							Object.assign( document.body.style, {
								'padding-top': '',
							} );

							document.body.classList.remove( 'is-showing-nav' );
							document.body.classList.remove( 'is-scrolled' );
						} );
					}
				};
			} )();

			this.on( 'scroll', () => {
				if ( isBlocked ) {
					clearTimeout( debounce );

					debounce = setTimeout( () => {
						handler();
					}, 128 );

					return;
				}

				isBlocked = true;

				handler();

				setTimeout( () => {
					isBlocked = false;
				}, 256 );
			}, window, {
				passive: true,
			} );

			this.on( 'mr-hero:current', ( e ) => {
				if ( e.detail.light_background ) {
					window.requestAnimationFrame( () => {
						document.body.classList.add( 'is-hero-with-light-background' );
					} );
				} else if ( document.body.classList.contains( 'is-hero-with-light-background' ) ) {
					window.requestAnimationFrame( () => {
						document.body.classList.remove( 'is-hero-with-light-background' );
					} );
				}
			}, document.body );
		}
	},
} );
