import { defineCustomElement, BaseController, Template } from '@mrhenry/wp--custom-elements-helpers';

const GDPR_COOKIE_ACCEPTED = 'GDPR_COOKIE_ACCEPTED';
const GDPR_COOKIE_ACCEPTED_AT = 'GDPR_COOKIE_ACCEPTED_AT';

const setCookie = ( key, value, expiration ) => {
	let expires = '';

	if ( expiration instanceof Date ) {
		expires = `expires=${expiration.toUTCString()};`;
	}

	document.cookie = `${key.trim()}=${value.toString().trim()};path=/;secure;${expires}`;
};

const getAllCookies = () => {
	const cookies = document.cookie.split( ';' ).filter( ( string ) => {
		return !!string;
	} );

	const out = {};

	cookies.map( ( string ) => {
		return string.split( '=' );
	} ).forEach( ( pair ) => {
		if ( 2 === pair.length ) {
			out[pair[0].trim()] = pair[1].trim();
		} else if ( 1 === pair.length ) {
			out[pair[0].trim()] = '';
		}
	} );

	return out;
};

const getCookie = ( key ) => {
	return getAllCookies()[key];
};

const unsetCookie = ( key ) => {
	document.cookie = `${key.trim()}=;expires=${new Date( 0 ).toUTCString()}`;
};

defineCustomElement( 'mr-gdpr-cookie', {
	attributes: [
		{
			attribute: 'hidden',
			type: 'boolean',
		},
	],
	controller: class extends BaseController {
		bind() {
			this.on( 'click .js-gdpr-cookie-accept', () => {
				this.enableCookies();
				this.hidden = true;
			} );
		}

		render() {
			const accepted = getCookie( GDPR_COOKIE_ACCEPTED ) === GDPR_COOKIE_ACCEPTED;

			if ( accepted ) {
				this.emit( 'gdpr-cookie:accepted' );
			} else {
				this.hidden = false;
			}
		}

		enableCookies() {
			const duration = 90 * 24 * 3600 * 1000; // 90 days in milliseconds
			const expiration = new Date();
			expiration.setTime( expiration.getTime() + duration );

			setCookie( GDPR_COOKIE_ACCEPTED, GDPR_COOKIE_ACCEPTED, expiration );
			setCookie( GDPR_COOKIE_ACCEPTED_AT, ( new Date() ).getTime(), expiration );
			this.emit( 'gdpr-cookie:accepted' );
		}

		disableCookies() {
			unsetCookie( GDPR_COOKIE_ACCEPTED );
			unsetCookie( GDPR_COOKIE_ACCEPTED_AT );
			this.emit( 'gdpr-cookie:disabled' );
		}
	},
} );

defineCustomElement( 'mr-gdpr-require-cookies', {
	controller: class extends BaseController {
		resolve() {
			const accepted = getCookie( GDPR_COOKIE_ACCEPTED ) === GDPR_COOKIE_ACCEPTED;

			if ( accepted ) {
				return Promise.resolve( true );
			}

			return new Promise( ( resolve ) => {
				this.on( 'gdpr-cookie:accepted', () => {
					resolve();
				}, window );
			} );
		}

		render() {
			window.requestAnimationFrame( () => {
				const template = new Template( '.tpl-gdpr-require-cookies', this.el );
				this.innerHTML = '';
				this.el.appendChild( template.render() );
			} );
		}
	},
} );
