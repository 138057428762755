import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-hero', {
	attributes: [
		{
			attribute: 'auto',
			type: 'int',
		},
		{
			attribute: 'current',
			type: 'int',
		},
	],
	controller: class extends BaseController {
		currentChangedCallback( from, to ) {
			this.emit( 'mr-hero:current', {
				current: to,
				light_background: this.elements.items[to].classList.contains( 'hero__item--has-light-background' ),
			} );

			this.render();
		}

		autoChangedCallback() {
			this.start();
		}

		start() {
			this.stop();

			if ( this.auto && 0 < this.auto ) {
				this.looper = setInterval( () => {
					this.next();
				}, this.auto );
			}
		}

		stop() {
			if ( this.looper ) {
				clearInterval( this.looper );
				this.looper = null;
			}
		}

		next() {
			if ( this.current < this.elements.items.length - 1 ) {
				this.current = this.current + 1;
			} else {
				this.current = this.elements.items.length - 1;
			}
		}

		previous() {
			if ( 0 < this.current ) {
				this.current = this.current - 1;
			} else {
				this.current = 0;
			}
		}

		resolve() {
			if ( 1 === this.el.querySelectorAll( '.js-hero-item' ).length ) {
				// Keep hanging, don't activate if empty
				return new Promise( () => {} );
			}

			return super.resolve();
		}

		init() {
			this.elements = {};
			this.elements.parent = this.el.querySelector( '.js-hero-parent' );
			this.elements.track = this.el.querySelector( '.js-hero-track' );
			this.elements.items = Array.from( this.el.querySelectorAll( '.js-hero-item' ) );
			this.elements.buttons = {
				previous: this.el.querySelector( '.js-hero-navigation-previous' ),
				next: this.el.querySelector( '.js-hero-navigation-next' ),
			};

			if ( !this.current ) {
				this.current = 0;
			}

			this.emit( 'mr-hero:current', {
				current: this.current,
			} );

			this.start();
		}

		bind() {
			if ( this.elements.buttons ) {
				this.on( 'click', () => {
					this.next();
				}, this.elements.buttons.next );

				this.on( 'click', () => {
					this.previous();
				}, this.elements.buttons.previous );
			}
		}

		render() {
			if ( this.elements.buttons ) {
				if ( 0 === this.current ) {
					this.elements.buttons.previous.classList.add( 'is-disabled' );
				} else {
					this.elements.buttons.previous.classList.remove( 'is-disabled' );
				}

				if ( this.current === ( this.elements.items.length - 1 ) ) {
					this.elements.buttons.next.classList.add( 'is-disabled' );
				} else {
					this.elements.buttons.next.classList.remove( 'is-disabled' );
				}
			}

			this.scroll();
		}

		scroll() {
			const position = this.elements.items[this.current].offsetLeft;
			const transform = `translateX(-${Math.round( position )}px)`;

			Object.assign( this.elements.track.style, {
				transform: transform,
				webkitTransform: transform,
			} );
		}

		destroy() {
			this.stop();
			super.destroy();
		}
	},
} );
