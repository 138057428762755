import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-auto-form', {
	attributes: [],
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.form = this.el.querySelector( '.js-auto-form' );
			this.elements.inputs = Array.from( this.el.querySelectorAll( '.js-auto-form-input' ) );
		}

		bind() {
			this.elements.inputs.forEach( ( input ) => {
				this.on( 'change', () => {
					this.elements.form.submit();
				}, input );
			} );
		}
	},
} );
