import { defineCustomElement, BaseController, attachMediaAttributes } from '@mrhenry/wp--custom-elements-helpers';

let needsReinit = false;

class AccordionNavigationController extends BaseController {
	resolve() {
		return super.resolve().then( () => {
			return this.whenMediaMatches();
		} );
	}

	init() {
		this.elements = {};
		this.elements.toggles = Array.from( this.el.querySelectorAll( '.js-accordion-navigation-toggle' ) );
		this.elements.information = Array.from( this.el.querySelectorAll( '.js-accordion-navigation-information' ) );
	}

	bind() {
		this.elements.toggles.forEach( ( toggle ) => {
			this.on( 'click', ( e, target ) => {
				e.preventDefault();
				this.toggle( target );
			}, toggle );
		} );

		this.watchMedia( () => {
			if ( needsReinit ) {
				needsReinit = false;

				super.resolve().then( () => {
					this.el.classList.add( 'is-resolved' );
					this.init();
					this.bind();
					this.render();
				} );
			}
		}, () => {
			needsReinit = true;
			this.unbind();
			this.destroy();
		} );
	}

	hide( toggle ) {
		toggle.setAttribute( 'aria-expanded', 'false' );

		const activeInfo = this.elements.information.find( ( item ) => {
			return item.getAttribute( 'aria-labelledby' ) === toggle.id;
		} );

		activeInfo.setAttribute( 'hidden', 'hidden' );
	}

	show( toggle ) {
		if ( !this.allowMultiple ) {
			this.hideAll();
		}

		toggle.setAttribute( 'aria-expanded', 'true' );

		const activeInfo = this.elements.information.find( ( item ) => {
			return item.getAttribute( 'aria-labelledby' ) === toggle.id;
		} );

		activeInfo.removeAttribute( 'hidden' );
	}

	toggle( toggle ) {
		if ( 'true' === toggle.getAttribute( 'aria-expanded' ) && !( this.alwaysOpen && !this.allowMultiple ) ) {
			this.hide( toggle );
		} else {
			this.show( toggle );
		}
	}

	hideAll() {
		this.elements.toggles.forEach( ( toggle ) => {
			this.hide( toggle );
		} );
	}
}

attachMediaAttributes( AccordionNavigationController );

defineCustomElement( 'mr-accordion-navigation', {
	attributes: [
		{
			attribute: 'allow-multiple',
			type: 'bool',
		},
		{
			attribute: 'always-open',
			type: 'bool',
		},
	],
	controller: AccordionNavigationController,
} );
