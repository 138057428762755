import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-accordion', {
	attributes: [
		{
			attribute: 'allow-multiple',
			type: 'bool',
		},
		{
			attribute: 'always-open',
			type: 'bool',
		},
	],
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.buttons = Array.from( this.el.querySelectorAll( '[role="heading"] button' ) );
			this.elements.information = Array.from( this.el.querySelectorAll( '[role="information"]' ) );
		}

		bind() {
			this.elements.buttons.forEach( ( button ) => {
				this.on( 'click', ( e, target ) => {
					e.preventDefault();
					this.toggle( target );
				}, button );
			} );
		}

		hide( button ) {
			button.setAttribute( 'aria-expanded', 'false' );

			const activeInfo = this.elements.information.find( ( item ) => {
				return item.getAttribute( 'aria-labelledby' ) === button.id;
			} );

			if ( !activeInfo ) {
				return;
			}

			activeInfo.setAttribute( 'hidden', 'hidden' );
		}

		show( button ) {
			if ( !this.allowMultiple ) {
				if ( button.getAttribute( 'nested' ) ) {
					this.hideAll( button.getAttribute( 'nested' ) );
				} else {
					this.hideAll();
				}
			}

			button.setAttribute( 'aria-expanded', 'true' );

			const activeInfo = this.elements.information.find( ( item ) => {
				return item.getAttribute( 'aria-labelledby' ) === button.id;
			} );

			if ( !activeInfo ) {
				return;
			}

			activeInfo.removeAttribute( 'hidden' );
		}

		toggle( button ) {
			if ( 'true' === button.getAttribute( 'aria-expanded' ) && !( this.alwaysOpen && !this.allowMultiple ) ) {
				this.hide( button );
			} else {
				this.show( button );
			}
		}

		hideAll( exception = false ) {
			this.elements.buttons.forEach( ( button ) => {
				if ( button.getAttribute( 'aria-controls' ) !== exception ) {
					this.hide( button );
				}
			} );
		}
	},
} );
