import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-showcase', {
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.images = this.el.querySelector( '.js-showcase-images' );
			this.elements.captions = this.el.querySelector( '.js-showcase-captions' );
		}

		bind() {
			this.on( 'mr-slideshow:current', ( e ) => {
				this.elements.images.current = parseInt( e.detail.current, 10 );
			}, this.elements.captions );
		}
	},
} );
