import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-faq', {
	attributes: [],
	controller: class extends BaseController {
		init() {
			this.elements = {};
			this.elements.buttons = Array.from( this.el.querySelectorAll( '.js-faq-filter-button' ) );
			this.elements.groups = Array.from( this.el.querySelectorAll( '.js-faq-group' ) );
		}

		bind() {
			this.elements.buttons.forEach( ( button ) => {
				this.on( 'click', () => {
					if ( !button.classList.contains( 'is-active' ) ) {
						this.resetButtons();

						if ( button.getAttribute( 'faq-filter-id' ) ) {
							this.hideAll();
							this.showGroup( button.getAttribute( 'faq-filter-id' ) );
						} else {
							this.showAll();
						}

						button.classList.add( 'is-active' );
					}
				}, button );
			} );
		}

		hideAll() {
			this.elements.groups.forEach( ( group ) => {
				group.classList.add( 'is-hidden' );
			} );
		}

		showAll() {
			this.elements.groups.forEach( ( group ) => {
				group.classList.remove( 'is-hidden' );
			} );
		}

		showGroup( groupID ) {
			this.el.querySelector( `.js-faq-group[faq-group-id="${groupID}"]` ).classList.remove( 'is-hidden' );
		}

		resetButtons() {
			this.elements.buttons.forEach( ( button ) => {
				button.classList.remove( 'is-active' );
			} );
		}
	},
} );
