import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-search-toggle', {
	attributes: [
		{
			attribute: 'target',
			type: 'string',
		},
	],
	controller: class extends BaseController {
		init() {
			this.elements = {};

			if ( this.target ) {
				this.elements.target = document.querySelector( this.target );
				this.elements.body = this.elements.target.querySelector( '.js-search-form' );
				this.elements.focustarget = this.elements.target.querySelector( '.js-search-field' );
			}
		}

		bind() {
			this.on( 'click', () => {
				if ( this.elements.target ) {
					this.toggle();
				}
			} );

			this.on( 'click', ( e ) => {
				if ( this.elements.body && this.elements.target &&
					!this.elements.body.contains( e.target ) ) {
					this.toggle();
				}
			}, this.elements.target );

			this.on( 'keydown', ( e ) => {
				if ( 27 === e.keyCode &&
					this.elements.target.classList.contains( 'is-active' ) ) {
					this.toggle();
				}
			}, window, {
				passive: true,
			} );
		}

		toggle() {
			if ( this.elements.target.classList.contains( 'is-active' ) ) {
				this.elements.target.classList.remove( 'is-showing' );

				setTimeout( () => {
					this.elements.target.classList.remove( 'is-active' );
					this.elements.focustarget.blur();
					this.elements.focustarget.value = '';
				}, 256 );
			} else {
				this.elements.target.classList.add( 'is-active' );

				setTimeout( () => {
					this.elements.target.classList.add( 'is-showing' );
				}, 100 );

				this.elements.focustarget.focus();
			}
		}
	},
} );
